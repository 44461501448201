<template>
  <card>
    <body-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('globalTrans.details') }}</h4>
        </template>
          <template v-slot:headerAction>
            <b-button @click="back" class="text-right"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
          </template>
        <template v-slot:body>
          <b-row>
            <b-col sm="12">
              <b-tabs content-class="mt-0" fill>
                <b-tab :title="$t('license_management.application')" active>
                  <div v-if="loading">
                    <Loading />
                  </div>
                  <b-overlay v-else>
                    <div class="p-3">
                      <b-row>
                        <b-col sm="12">
                          <div class="text-right">
                            <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                              <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                            </b-button>
                          </div>
                          <div>
                            <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                              <div class="" style="width:50%"><b>{{ $t('license_management.agreement_no') }}</b> : {{ appDetail.application_id ? EngBangNum(appDetail.application_id) : '' }}</div>
                              <div class="text-right" style="width:50%"><b>{{ $t('externalLrcpn.issue_date') }}</b> : {{ formattedDate | dateFormat }}</div>
                            </div>
                              <div class="stepper-wrapper">
                                <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                                  v-for="(item, index) in stepList" :key="index">
                                  <div class="step-counter">
                                    <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                                    <span v-else>{{ $n(index+1) }}</span>
                                  </div>
                                  <div class="step-name">{{ item.text }}</div>
                                </div>
                              </div>
                            <!-- Applicant info -->
                            <div class="application-form-wrapper application-form-view-wrapper mt-5">
                              <div class="application-itmes">
                                 <div class="group-form-card">
                                  <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="md"
                                    label-class="font-weight-bold pt-0" class="form-view-item">
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('globalTrans.applicant_name')" label-for="ApplicantNameEn">
                                          <p class="input-field"> {{ currentLocale === 'en' ? appDetail.applicant_name : appDetail.applicant_name_bn }} </p>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('globalTrans.email')" label-for="ApplicantNameEn">
                                          <p class="input-field">{{ appDetail.applicant_email }}</p>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('globalTrans.mobile')" label-for="ApplicantNameEn">
                                          <p class="input-field">{{ appDetail.applicant_mobile ? EngBangNum(appDetail.applicant_mobile) : '' }}</p>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('globalTrans.company_name')" label-for="DesignationEn">
                                          <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_name : appDetail.company_name_bn }}</p>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.office_address')" label-for="ApplicantNameEn">
                                          <p class="input-field">{{ currentLocale === 'en' ? appDetail.office_address : appDetail.office_address_bn }}</p>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.warehouse_address')" label-for="DesignationEn">
                                          <p class="input-field">{{ currentLocale === 'en' ? appDetail.warehouse_address : appDetail.warehouse_address_bn }}</p>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="12">
                                        <b-form-group :label="$t('lrcpnServicePanel.purpose_of_import')" label-for="ApplicantNameEn">
                                          <p class="input-field">{{ appDetail.purpose_of_import }}</p>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                            <!-- Import information -->
                            <div class="application-form-wrapper application-form-view-wrapper mt-5">
                              <div class="application-itmes">
                                 <div class="group-form-card">
                                  <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.acid_import_info')" label-size="md"
                                    label-class="font-weight-bold pt-0" class="form-view-item">
                                    <b-row>
                                      <b-overlay :show="addMoreLoading">
                                            <b-table-simple style="width: 100%" bordered>
                                                <b-tr>
                                                    <b-th class="text-center" style="width: 20%;"> {{ $t('lrcpnServicePanel.acid_name') }}</b-th>
                                                    <b-th class="text-center" style="width: 80%;"> {{ $t('globalTrans.description') }}</b-th>
                                                </b-tr>
                                                <template v-if="appDetail.import_infos.length">
                                                  <slot v-for="(item, index) in appDetail.import_infos">
                                                        <b-tr :key="index">
                                                            <b-td class="text-center">
                                                                {{ getAcidName(item.acid_id) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ currentLocale === 'en' ? item.description : item.description_bn }}
                                                            </b-td>
                                                        </b-tr>
                                                    </slot>
                                                </template>
                                                <template v-else>
                                                    <b-tr>
                                                        <b-td colspan="2" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                                    </b-tr>
                                                </template>
                                            </b-table-simple>
                                        </b-overlay>
                                    </b-row>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                            <!-- Attachment info -->
                            <div class="application-form-wrapper application-form-view-wrapper mt-5">
                              <div class="application-itmes">
                                 <div class="group-form-card">
                                  <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.attachment_info')" label-size="md"
                                    label-class="font-weight-bold pt-0" class="form-view-item">
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.nid_file')" label-for="DesignationEn">
                                           <div v-if="appDetail.attachment.nid_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.nid_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.irc_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.irc_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.irc_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.trade_license_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.trade_license_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.trade_license_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.applicant_signature')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.applicant_signature">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.applicant_signature"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.tin_certificate_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.tin_certificate_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.tin_certificate_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.bin_certificate_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.bin_certificate_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.bin_certificate_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.environmental_clearance_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.environmental_clearance_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.environmental_clearance_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.fire_license_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.fire_license_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.fire_license_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.dress_authority_recommended_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.dress_authority_recommended_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.dress_authority_recommended_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.warehouse_layout_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.warehouse_layout_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.warehouse_layout_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.drug_control_clearance_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.drug_control_clearance_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.drug_control_clearance_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.inspection_report_district_administration_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.inspection_report_district_administration_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.inspection_report_district_administration_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col sm="6">
                                        <b-form-group :label="$t('lrcpnServicePanel.acis_transportation_permission_district_administration_file')" label-for="DesignationEn">
                                          <div v-if="appDetail.attachment.acis_transportation_permission_district_administration_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="lrcpnServiceBaseUrl + appDetail.attachment.acis_transportation_permission_district_administration_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                          </div>
                                          <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-overlay>
                </b-tab>
                <b-tab v-if="appDetail.forwards && $store.state.Auth.authUser.user_type === 1" :title="$t('teaGardenPanel.forward_history')">
                  <div v-if="forwarLoad || loading" class="col-md-12">
                    <forward-loading></forward-loading>
                  </div>
                  <div v-else  class="row">
                    <div class="col-md-10 offset-md-1">
                      <div v-for="(forward, index) in appDetail.forwards" :key="index">
                        <b-card>
                          <b-card-title> <h5 style="font-size:17px">{{ getCardTitle(forward.step_id) }}</h5> </b-card-title>
                          <b-card-sub-title style="font-size:14px">
                            <span class="badge badge-success">{{ $t('globalTrans.sender') }}</span> : {{ getUserName(forward.sender_id) }}  <i class=" ml-3 ri-arrow-right-line"></i>
                            <span class="badge badge-primary ml-3">{{ $t('globalTrans.receiver') }}</span> : {{ getUserName(forward.receiver_id) }} <span class="badge badge-info">{{ getDesignationName(forward.designation_id)}}</span>
                          </b-card-sub-title>
                          <b-card-text class="mt-3">
                            <small class="mt-4">
                            <b>{{ $t('globalTrans.date') }}</b> : {{ forward.created_at | dateFormatTime }}
                          </small>
                          <br>
                            <b>{{ $t('globalTrans.note') }}</b> : <span>{{ currentLocale === 'en' ? forward.note_en : forward.note_bn }}</span>
                          </b-card-text>
                          <p class="text-right" v-if="forward.attachment"><b>{{$t('globalTrans.attachment')}}</b>
                                <a target="_blank" :href="lrcpnServiceBaseUrl + (isImage(forward.attachment) ? 'storage/' : '') + forward.attachment"><i style="font-weight:bold" class="ri-download-line download-btn"></i>
                                </a>
                          </p>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-col>
              <b-col md="12" class="text-right mt-2">
                <b-button @click="back"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
              </b-col>
          </b-row>
        </template>
    </body-card>
  </card>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { acidLicenseClearanceApplicationShowApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ForwardLoading from './loading/ForwardLoading.vue'
import Loading from './loading/Details.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Loading,
    ForwardLoading
  },
  data () {
    return {
      lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      forwarLoad: false,
      appDetail: {
        created_at: null
      },
      search: {
        company_name: '',
        limit: 10
      },
      items: [],
      // listData: [],
      profile: this.$store.state.ExternalUserService.eBizPanel.associationProfile,
      associationInfoList: [],
      warehouseList: [],
      viewitemId: 0,
      userList: [],
      users: []
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  computed: {
      loading: function () {
          return this.$store.state.commonObj.loading
      },
      currentLocale () {
        return this.$i18n.locale
      },
      formattedDate () {
      return this.appDetail.created_at.slice(0, 10)
    },
      typeOfBusinessList () {
        return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
      },
      appId () {
        return this.$route.params.id
      },
      stepList () {
        const setp = [
          { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Inspection Pending' : 'পরিদর্শন প্রক্রিয়াধীন', text_en: 'Inspection Pending', text_bn: 'পরিদর্শন প্রক্রিয়াধীন' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'Inspection Complete' : 'পরিদর্শন সম্পন্ন', text_en: 'Inspection Complete', text_bn: 'পরিদর্শন সম্পন্ন' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'Approval' : 'অনুমোদন', text_en: 'Approval', text_bn: 'অনুমোদন' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
          { value: 8, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
        ]
        if (this.appDetail.status === 8) {
          return setp.filter(item => item.value !== 7)
        } else {
          return setp.filter(item => item.value !== 8)
        }
      },
      masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
      }
  },
  methods: {
    async getForwardedUsers (forwards) {
      this.forwarLoad = true
      var userIds = []
      forwards.map((item, key) => {
        userIds.push(item.sender_id)
        userIds.push(item.receiver_id)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.users = response
          } else {
            this.users = []
          }
          this.forwarLoad = false
      })
    },
    getUserName (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    getTypeOfBussiness (businessId) {
          const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1).find(item => item.value === businessId)
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
    },
    EngBangNum (n) {
          if (this.$i18n.locale === 'bn') {
              return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
          } else {
              return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
          }
    },
    getCouncilName (councilNameId) {
        const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.councilInfoList.filter(item => item.status === 1).find(item => item.value === councilNameId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getAssociationName (associationId) {
        const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.associationInfoList.filter(item => item.status === 1).find(item => item.value === associationId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(lrcpnServiceBaseUrl, acidLicenseClearanceApplicationShowApi + '/' + this.appId)
      if (result.success) {
        this.appDetail = result.data
          // if (this.appDetail.application.forwards.length) {
          //   this.forwardsShow = true
          // }
          if (this.appDetail.forwards.length) {
            this.getForwardedUsers(result.data.forwards)
          }
          // if (this.appDetail.application.recommendations.length) {
          //   this.recommendationShow = true
          // }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    async pdfExport () {
      this.loading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 1, app_id: this.appId })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(lrcpnServiceBaseUrl, acidLicenseClearanceApplicationShowApi + '/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      this.loading = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getAcidName (id) {
        const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    back () {
      this.$router.go(-1)
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => parseInt(item.value) === parseInt(id))
      if (obj) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
      return ''
    },
    getCardTitle (id) {
      const toStep = this.$store.state.LrcpnService.commonObj.acidLicenseStepList.find(item => item.value === parseInt(id))
      if (typeof toStep !== 'undefined') {
        return toStep.text
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.tag {
  font-weight: 600;
  border-left: 4px solid #1c4261;
  padding-left: 6px;
}
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 2px 4px;
}
.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}
.no-btn {
  color: red;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
  margin-right: 33px !important;
}
.tag {
  font-weight: 600;
  border-left: 4px solid #1c4261;
  padding-left: 6px;
}
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 2px 4px;
}
.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}
.no-btn {
  color: red;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
  margin-right: 33px !important;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  }
  .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
      font-size: 12px;
  }
  }

  .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
  }

  .stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
  }

  .stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
  color: white;
  }

  .stepper-item.active {
  font-weight: bold;
  }

  .stepper-item.completed .step-counter {
  background-color: #4bb543;
  color:white;
  }

  .stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
  }

  .stepper-item:first-child::before {
  content: none;
  }
  .stepper-item:last-child::after {
  content: none;
  }
  .step-name {
      color: black;
      font-weight: bold;
      font-size: 11px;
  }
.tagTwo {
position: relative;
display: inline-block;
border-radius: 6px;
clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
background: hsl(250deg, 100%, 40%);
padding: 5px 25px;
font-weight: 600;
font-size: 12px;
color: #FFF;
transition: clip-path 500ms;
}
.tagTwo:after {
content: '';
position: absolute;
top: 0;
left: 0;
width: 20px;
height: 20px;
background: hsl(252deg, 100%, 60%);
box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
border-radius: 0 0 6px 0;
transition: transform 500ms;
}
</style>
